import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import authorization from './Authorization';
import client from './Client';
import { unauthorizedMW } from './Middleware';
import miscellaneous from './Miscellaneous';
import { networkApi } from './Network';
import revision from './TableServerRevision';
import userList from './Users';

const persistConfig = {
  key: 'root',
  storage,
};

const store = configureStore({
  reducer: combineReducers({
    [networkApi.reducerPath]: networkApi.reducer,
    authorization,
    miscellaneous: persistReducer(persistConfig, miscellaneous),
    client,
    userList,
    revision,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(networkApi.middleware)
      .concat(unauthorizedMW),
  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

const persistor = persistStore(store);

export { persistor, store };
