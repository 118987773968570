import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, useProSidebar } from 'react-pro-sidebar';
import { matchPath, useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useWindowSize } from 'usehooks-ts';
import { faAnglesLeft, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BopsLogoExpanded, BopsTitleLogo, ClientLogoIscpg, gs1_mexico_logo, gs1_mexico_logo_sm } from '@Assets';
import { Permission } from '@Models';
import { screenSize } from '@Themes';
import config from '../../config';
import ModalResourceVideos, { ModalResourceVideosRef } from '../ModalResourceVideos/modalResourceVideos.container';
import { ConfigurationKey } from '../WithValidConfiguration/withValidConfiguration';
import {
  Anchor,
  Content,
  Footer,
  Header,
  LogoutContainer,
  PageLinkContainer,
  Separator,
  SidebarContainer,
  SidebarLink,
  SideBarText,
  StyledExtraButton,
  StyledMenuItem,
  StyledNavMenuItem,
  StyledSidebar,
  StyledSubMenu,
} from './sidebar.styles';

export interface MenuItemProps {
  permission?: Permission;
  configurationKey?: ConfigurationKey;
  label: string;
  icon?: JSX.Element;
  svg?: string;
  link?: string;
  enabled?: boolean;
  externalLink?: string;
  extraIcon?: JSX.Element;
  extraIconTooltip?: string;
  extraIconOnClick?: () => void;
  type?: string;
  hideWhenDisabled?: boolean;
  defaultOpen?: boolean;
  submenu?: MenuItemProps[];
  onClick?: () => void;
}

interface SidebarProps {
  menuItems: MenuItemProps[];
  onLogout: () => void;
  showResources: boolean;
  dataRepository?: string;
  account: string;
  logo?: string;
}

const Sidebar = ({ menuItems, onLogout, showResources, dataRepository, account, logo }: SidebarProps) => {
  const { collapseSidebar, collapsed } = useProSidebar();
  const { width } = useWindowSize();
  const modalResourceRef = useRef<ModalResourceVideosRef>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (width > 0 && width < screenSize.autoCollapseSideBar) {
      collapseSidebar(true);
    }
  }, [width]);

  const buildTooltip = ({ id, content }: { id: string; content: string }) => {
    return collapsed && <ReactTooltip id={id} positionStrategy="fixed" place="right" content={content} offset={18} />;
  };

  const buildMenuItem = ({
    label,
    icon,
    link,
    enabled,
    externalLink,
    extraIcon,
    extraIconTooltip,
    extraIconOnClick,
    submenu,
    defaultOpen,
    svg,
    type,
    onClick,
  }: MenuItemProps) => {
    const isPathActive = !!matchPath(decodeURIComponent(useLocation().pathname), link || '');
    const tooltipId = `tooltip-${label}`;

    const svgIcon = <img src={svg} alt="icon" className="w-[25px] mr-2" />;

    const handleClick = (item) => {
      if (item?.type === 'videos') {
        return () => modalResourceRef.current?.showModal();
      }
      if (item?.onClick) return onClick;
      return null;
    };

    return (
      <StyledNavMenuItem key={label} $collapsed={collapsed} $isPathActive={isPathActive}>
        {externalLink ? (
          <PageLinkContainer data-tooltip-id={tooltipId}>
            <Anchor href={externalLink} target="_blank" rel="noreferrer">
              {icon}
              <SideBarText $collapsed={collapsed}>{label}</SideBarText>
            </Anchor>
          </PageLinkContainer>
        ) : submenu ? (
          <StyledSubMenu
            data-tooltip-id={tooltipId}
            icon={svg ? svgIcon : icon}
            $collapsed={collapsed}
            label={label}
            defaultOpen={defaultOpen}>
            {submenu.map((item) => {
              const isPathActiveSubMenu = !!matchPath(decodeURIComponent(useLocation().pathname), item.link || '');
              const svgIconSub = <img src={item?.svg} alt="icon" className="w-[25px] mr-2" />;
              return (
                <StyledNavMenuItem key={item?.label} $collapsed={collapsed} $isPathActive={isPathActiveSubMenu}>
                  {item?.link ? (
                    <StyledMenuItem
                      data-tooltip-id={`tooltip-${item.label}`}
                      icon={item?.svg ? svgIconSub : item?.icon}
                      $isActive={isPathActiveSubMenu}
                      $isDisabled={!item?.enabled}
                      component={<SidebarLink to={item?.link ?? '/'} />}>
                      {item?.label}
                    </StyledMenuItem>
                  ) : item?.type || item?.onClick ? (
                    <StyledMenuItem
                      data-tooltip-id={`tooltip-${item.label}`}
                      onClick={() => handleClick(item)}
                      $isActive={isPathActiveSubMenu}
                      $isDisabled={!item?.enabled}
                      icon={item?.svg ? svgIconSub : item?.icon}>
                      {item?.label}
                    </StyledMenuItem>
                  ) : item?.externalLink ? (
                    <StyledMenuItem
                      $isDisabled={!item?.enabled}
                      $isActive={isPathActiveSubMenu}
                      icon={item?.svg ? svgIconSub : item?.icon}
                      data-tooltip-id={`tooltip-${item.label}`}
                      href={item?.externalLink}
                      target="_blank"
                      rel="noreferrer">
                      {item?.label}
                    </StyledMenuItem>
                  ) : (
                    <div>{item?.label}</div>
                  )}
                </StyledNavMenuItem>
              );
            })}
          </StyledSubMenu>
        ) : (
          <StyledMenuItem
            data-tooltip-id={tooltipId}
            icon={svg ? svgIcon : icon}
            $isActive={isPathActive}
            $isDisabled={!enabled}
            component={<SidebarLink to={link ?? '/'} />}>
            {!collapsed && label}
            {extraIcon && !collapsed && (
              <>
                <StyledExtraButton
                  data-tooltip-id={`${tooltipId}-extra`}
                  $collapsed={collapsed}
                  $isPathActive={isPathActive}
                  onClick={extraIconOnClick}>
                  {extraIcon}
                </StyledExtraButton>

                <ReactTooltip
                  id={`${tooltipId}-extra`}
                  positionStrategy="fixed"
                  place="right"
                  content={extraIconTooltip}
                  offset={25}
                />
              </>
            )}
          </StyledMenuItem>
        )}
        {buildTooltip({ id: tooltipId, content: label })}
      </StyledNavMenuItem>
    );
  };
  const menuItemStyles = {
    button: () => {
      return {
        '&:hover': {
          backgroundColor: '#101828',
          marginRight: '0px',
        },
      };
    },
  };

  const customLogos = {
    iscpg: {
      logo: ClientLogoIscpg,
      height: 100,
      width: 100,
      collapsedHeight: 42,
      collapsedWidth: 42,
      margingLeft: '30px',
      collapsedMargingLeft: '0px',
    },
  };

  const getLogo = () => {
    if (config.isGs1Flavor)
      return collapsed ? (
        <div>
          <img src={gs1_mexico_logo_sm} alt="gs1mex-logo" height={35} width={35} />
        </div>
      ) : (
        <img src={gs1_mexico_logo} alt="gs1mex-logo" height={100} width={110} />
      );

    if (logo && logo in customLogos)
      return (
        <img
          src={customLogos[logo].logo}
          alt="account-logo"
          height={collapsed ? customLogos[logo].collapsedHeight : customLogos[logo].height}
          width={collapsed ? customLogos[logo].collapsedWidth : customLogos[logo].width}
        />
      );

    return collapsed ? <BopsLogoExpanded height={35} width={35} /> : <BopsTitleLogo height={35} width={125} />;
  };

  return (
    <SidebarContainer>
      <StyledSidebar>
        <Header $collapsed={collapsed}>{getLogo()}</Header>
        <Separator />
        <Content>
          <Menu menuItemStyles={menuItemStyles}>{menuItems.map((menuItem) => buildMenuItem(menuItem))}</Menu>
        </Content>
        <Footer className="!bottom-[15px]">
          {buildTooltip({ id: 'tooltip-logout', content: t('Sidebar.Logout') })}
          <LogoutContainer data-tooltip-id="tooltip-logout" onClick={onLogout}>
            <FontAwesomeIcon icon={faSignOut} size="lg" transform={{ flipX: true }} />
            <SideBarText $collapsed={collapsed}>{t('Sidebar.Logout')}</SideBarText>
          </LogoutContainer>
          <LogoutContainer onClick={() => collapseSidebar()}>
            <FontAwesomeIcon icon={faAnglesLeft} size="lg" color="white" transform={{ flipX: collapsed }} />
            <SideBarText $collapsed={collapsed}>{t('Sidebar.Collapse')}</SideBarText>
          </LogoutContainer>
        </Footer>
        {showResources && <ModalResourceVideos ref={modalResourceRef} />}
      </StyledSidebar>
    </SidebarContainer>
  );
};

export default Sidebar;
