import { forwardRef, InputHTMLAttributes } from 'react';

export type InputProps = InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className = '', type, onChange, placeholder, disabled, id, ...props }, ref) => {
    return (
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        className={`w-full flex h-5 rounded-md border border-solid border-gray-100 p-2 focus:outline-none focus:border-primary disabled:cursor-not-allowed disabled:opacity-50 ${className}`}
        ref={ref}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
    );
  },
);

export default Input;
