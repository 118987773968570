import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row as ReactTableRow,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import ColumnPicker from '../ColumnPicker/columnPicker';
import IndeterminateCheckbox from '../IndeterminateCheckbox/indeterminateCheckbox';

export interface Column {
  Header?: string;
  accessor: string;
  Cell?: ({ row }) => void;
  width?: string;
}

export interface Row {
  values: object;
  depth: number;
  canExpand?: boolean;
  isExpanded?: boolean;
}

export interface TableFilter {
  column: string;
  value?: any;
}

interface TableProps {
  columns: Column[];
  data: object[];
  pagination?: boolean;
  sortable?: boolean;
  footer?: boolean;
  columnPicker?: boolean;
  selectable?: boolean;
  filters?: TableFilter[];
  pageSize: number;
  getFilteredRows?(rows: ReactTableRow<object>[]);
}

const Table = (props: TableProps) => {
  const { columns, data, pagination, sortable, footer, columnPicker, selectable, filters, getFilteredRows, pageSize } =
    props;

  const { t } = useTranslation();

  const {
    footerGroups,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state: { pageIndex },
    setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selectable) {
        hooks.visibleColumns.push((visibleColumn) => [
          {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            ),
            Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
          },
          ...visibleColumn,
        ]);
      }
    },
  );

  if (getFilteredRows) {
    getFilteredRows(rows);
  }

  useEffect(() => {
    filters?.forEach((filter) => {
      setFilter(filter.column, filter.value);
    });
  }, [filters]);

  const totalPagesToDisplay = 6;

  return (
    <div className="w-full overflow-x-auto">
      {columnPicker && (
        <div className="flex justify-end">
          <ColumnPicker allColumns={allColumns} getToggleHideAllColumnsProps={getToggleHideAllColumnsProps} />
        </div>
      )}
      <table className="border-collapse w-full" {...getTableProps()}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              const sortByToggleProps = sortable ? column.getSortByToggleProps() : {};
              return (
                <th
                  className=" text-black-100 text-[15px] leading-4 text-left bg-gray-50 border-l-0 border-r-0  border-solid border-gray-100 p-3 px-6 text-gray-400 font-medium"
                  {...column.getHeaderProps(sortByToggleProps)}>
                  {column.render('Header')}
                  {column.isSorted && <span>{column.isSortedDesc ? '  ↓' : '  ↑'}</span>}
                </th>
              );
            })}
          </tr>
        ))}
        <tbody {...getTableBodyProps()}>
          {pagination
            ? page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        className={` text-black-100 text-[15px] text-left border-l-0 border-r-0 border-t-0 border-b-1 border-solid border-gray-100 break-words leading-4 p-3 px-6 ${cell?.column?.width}`}
                        {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })
            : rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
        </tbody>

        {footer && (
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>

      {pagination && (
        <div className="flex place-items-center p-3 px-5">
          <span className="text-gray-300 text-sm">
            {t('Global.Page')} {pageIndex + 1} {t('Global.Of')} {pageCount || 1}
          </span>

          <div className="gap-2 flex ml-auto">
            <div
              className="border border-solid border-gray-100 rounded-md p-2 cursor-pointer w-fit"
              onClick={() => previousPage()}>
              <span className={`${pageIndex === 0 ? 'text-gray-200' : 'text-gray-300'} text-sm font-semibold`}>
                {t('Global.Previous')}
              </span>
            </div>
            <div
              className="border border-solid border-gray-100 rounded-md p-2 w-fit cursor-pointer"
              onClick={() => nextPage()}>
              <span
                className={`${
                  pageIndex !== pageCount - 1 && pageCount ? 'text-gray-300' : 'text-gray-200'
                } text-sm font-semibold`}>
                {t('Global.Next')}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Table.defaultProps = {
  pagination: false,
  sortable: true,
  footer: false,
  columnPicker: false,
  selectable: false,
  filters: [],
  getFilteredRows: undefined,
};

export default Table;
export type { ReactTableRow };
