import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { add_svg, icon_search } from '@Assets';
import { ButtonNew, Input, Select, UserAddModal, UserList } from '@Components';
import { UserProps } from '@Models';
import { SelectOptions } from '../../Components/Select/select';

const PAGE_SIZE = 9;

const User = ({ clients, users, fetchUsers, total }: UserProps) => {
  const { t } = useTranslation();
  const [client, setClient] = useState('');
  const [email, setEmail] = useState('');
  const [usersDisplay, setUsersDisplay] = useState(users);
  const [loading, setloading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [totalUsers, setTotal] = useState(total);

  useEffect(() => {
    setUsersDisplay(users);
  }, [users]);

  const filterUsers = useMemo(() => {
    return client ? usersDisplay.filter((user) => user['app_metadata.account'] === client) : usersDisplay;
  }, [client, usersDisplay]);

  const clientsObjects = useMemo(() => {
    const object = {};
    clients.forEach((element) => {
      if (!object[element.value]) {
        object[element.value] = element.label;
      }
    });

    return object;
  }, []);

  const clientsSort = useMemo(() => {
    return clients.sort((a, b) => a.label.localeCompare(b.label));
  }, [clients]);

  const handleClient = (e: SelectOptions): void => {
    setClient(e?.value || '');
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    setEmail(value);
  };

  return (
    <div className={`${loading ? 'pointer-events-none ' : ' '} bg-white w-full  h-full`}>
      <div className="flex flex-col p-8 bg-white md:pr-[100px] md:pl-[100px] md:pt-[20px]">
        <div className="flex">
          <div className="text-3xl font-semibold mb-6 text-black-100">{t('User.Title')}</div>
          <div className="ml-auto">
            <ButtonNew variant="primary" isSubmit={false} onClick={() => setOpenAdd(true)}>
              <div className="flex">
                <img src={add_svg} alt="add_user" className="w-4 text-white mr-2" />
                {t('User.Add')}
              </div>
            </ButtonNew>
          </div>
        </div>
        <div className="border border-gray-100 border-solid rounded-xl">
          <div className="flex flex-row mx-6 my-4 items-center">
            <span className="font-bold text-xl justify-start  text-black-100">
              {' '}
              {`${t('User.All')} (${client ? totalUsers : total})`}
            </span>
            <div className="ml-auto flex gap-2 w-[50%] lg:flex-row flex-col">
              <div className="w-full text-[15px]">
                <Select
                  placeholder={t('User.Select')}
                  defaultValue={client}
                  options={clientsSort}
                  onChange={handleClient}
                  isClearable
                />
              </div>
              <div className="relative">
                <Input
                  type="text"
                  id="email"
                  onChange={handleSearch}
                  className="sm:w-32 md:w-44 text-[15px] pl-8"
                  placeholder={t('User.Search')}
                />
                <div className="absolute inset-y-0 left-0 pl-2 flex items-center  pointer-events-none">
                  <img src={icon_search} alt="add_user" className="w-4 text-white mr-2" />
                </div>
              </div>
            </div>
          </div>

          <UserList
            usersData={filterUsers}
            clientsObjects={clientsObjects}
            total={total}
            client={client}
            emailQuery={email}
            setTotal={setTotal}
            pageSize={PAGE_SIZE}
          />
          <UserAddModal
            isOpen={openAdd}
            onClose={() => setOpenAdd(false)}
            clients={clientsSort}
            setloading={setloading}
            setUsersDisplay={setUsersDisplay}
            fetchUsers={fetchUsers}
            usersDisplay={usersDisplay}
            clientValue={client ? { value: client, label: clientsObjects ? clientsObjects[client] : '' } : undefined}
          />
        </div>
      </div>
    </div>
  );
};
export default User;
