import { useTranslation } from 'react-i18next';
import { GroupBase, OptionsOrGroups, StylesConfig } from 'react-select';
import { DefaultTheme } from 'styled-components';

import { bopsTheme } from '@Themes';
import { StyledSelect } from './select.styles';

export interface SelectOptions {
  value: string;
  label: string;
}

const Select = ({
  options,
  onChange,
  styles,
  applyTheme,
  defaultValue,
  identifier,
  isClearable,
  placeholder,
  error,
}: {
  options: OptionsOrGroups<unknown, GroupBase<unknown>>;
  onChange?: ((newValue: any, identifier: string | undefined) => void) | undefined;
  styles?: StylesConfig<unknown, boolean, GroupBase<unknown>>;
  applyTheme?: (theme: DefaultTheme) => DefaultTheme;
  defaultValue?: any;
  identifier?: string;
  placeholder?: string;
  isClearable?: boolean;
  error?: boolean;
}) => {
  const { t } = useTranslation();

  const customStyles = styles ?? {
    control: (base, state) => ({
      ...base,
      background: bopsTheme.colors.selectBackground,
      color: '#667085',
      // match with the menu
      borderRadius: 4,
      cursor: 'pointer',
      // Overwrittes the different states of border
      borderColor: error ? 'red' : state.isFocused ? bopsTheme.colors.bopsPurple : bopsTheme.colors.cardBorder,
      // Removes weird border around container
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      color: bopsTheme.colors.fontBlack,
      background: bopsTheme.colors.white,
      borderColor: bopsTheme.colors.cardBorder2,
      borderRadius: '5px',
      position: 'absolute',
      // kill the gap
      marginTop: 5,
      zIndex: 1009,
    }),
    menuList: (base) => ({
      ...base,
      borderColor: bopsTheme.colors.cardBorder,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const selectTheme =
    applyTheme ??
    ((theme: DefaultTheme): DefaultTheme => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: bopsTheme.colors.cardBackgroundTransparent,
        primary25: bopsTheme.colors.cardBackgroundTransparent,
        primary50: bopsTheme.colors.cardBackgroundTransparent,
        neutral80: bopsTheme.colors.black,
      },
    }));

  const internalOnChange = (newValue: any): void => {
    if (onChange) onChange(newValue, identifier);
  };

  return (
    <StyledSelect
      menuPortalTarget={document.body}
      styles={customStyles}
      theme={selectTheme(bopsTheme)}
      placeholder={placeholder ?? t('Global.Select')}
      defaultValue={defaultValue}
      onChange={internalOnChange}
      options={options}
      isClearable={isClearable}
    />
  );
};

export default Select;
