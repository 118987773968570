import { AppDispatch } from '../store';
import { refreshDataRevision } from './tableServerRevision.reducer';

const IncrementEvent = (event: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(refreshDataRevision({ event }));
  };
};

/* eslint-disable import/prefer-default-export */
export { IncrementEvent };
