import { useEffect, useMemo } from 'react';
import { use } from 'i18next';
import { Block } from 'notion-types';
import { defaultMapImageUrl, MapImageUrlFn, MapPageUrlFn, NotionRenderer } from 'react-notion-x';
import { Collection } from 'react-notion-x/build/third-party/collection';
import { Equation } from 'react-notion-x/build/third-party/equation';
import { NavLink } from 'react-router-dom';

import { ErrorMessage, Loading, NotFound } from '@Components';
import { useFetchData } from '@CustomHooks';
import { bopsApi } from '@Network';
import { Container, FetchingContainer, NotionContainer } from './documentation.styles';

interface DocumentationProps {
  pageId: string;
}

const Documentation = ({ pageId }: DocumentationProps) => {
  const { data, isLoading, error, refetch, isFetching } = useFetchData(bopsApi.getDocumentationPage, pageId);

  const idTable = useMemo(() => {
    let id = '';
    if (data?.collection) {
      id =
        Object.keys(data.collection).find(
          (key) => data?.collection?.[key]?.value?.schema?.title?.name === 'Tech Name',
        ) ?? '';
    }
    return id;
  }, [data?.collection]);

  const LinkX = (props) => {
    const { href, children } = props;
    if (children?.props?.block?.parent_id === idTable) {
      return <span>{children}</span>;
    }
    return (
      <NavLink to={href}>
        <span>{children}</span>
      </NavLink>
    );
  };

  useEffect(() => {
    if (!isLoading) refetch(pageId);
  }, [pageId]);

  if (isLoading) return <Loading />;
  if (error?.response?.status === 404) return <NotFound />;
  if (error || !data) return <ErrorMessage internalMessage={`Error getting documentation page: ${pageId}. ${error}`} />;

  const mapPageUrlFunc: MapPageUrlFn = (pageUUID: string) => `/documentation/${pageUUID}`;
  const mapImageUrlFunc: MapImageUrlFn = (url: string, block: Block) => {
    if (url.includes('secure.notion-static') || url.startsWith('https://prod-files-secure.s3.')) {
      const imageId = url.split('/').at(-2);
      return `/static/media/notion/${imageId}.png`;
    }

    return defaultMapImageUrl(url, block) || url;
  };

  return (
    <Container $isFetching={isFetching}>
      {isFetching && (
        <FetchingContainer>
          <Loading />
        </FetchingContainer>
      )}
      <NotionContainer>
        <NotionRenderer
          recordMap={data}
          fullPage
          darkMode={false}
          mapPageUrl={mapPageUrlFunc}
          mapImageUrl={mapImageUrlFunc}
          components={{ Collection, PageLink: LinkX, Equation }}
        />
      </NotionContainer>
    </Container>
  );
};

export default Documentation;
