import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProSidebar } from 'react-pro-sidebar';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ErrorMessage, Loading } from '@Components';
import { useFetchData } from '@CustomHooks';
import { bopsApi } from '@Network';
import { RootState } from '@Store';
import { screenSize } from '@Themes';
import Monitor from './monitor';

interface MonitorContainerProps {
  dashboards?: {
    name: string;
  }[];
}

const MonitorContainer = ({ dashboards }: MonitorContainerProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const { monitorName } = params;

  const location = useLocation();
  const { sheet } = location.state || {};
  const { collapseSidebar, collapsed } = useProSidebar();
  const { t } = useTranslation();
  const [currentMonitor, setCurrentMonitor] = useState(monitorName);

  const hasMultipleDashboards = dashboards && dashboards.length > 0;
  if (hasMultipleDashboards && location.pathname === '/monitor') {
    navigate(`/monitor/${dashboards[0].name}`);
  }

  if (!hasMultipleDashboards && location.pathname.includes('monitor') && location.pathname !== '/monitor') {
    navigate('/monitor');
  }

  const { data, isLoading, isFetching, error, refetch } = useFetchData(bopsApi.getQuickSightDashboard, monitorName);

  useEffect(() => {
    if (monitorName === currentMonitor) return;
    refetch(monitorName);
    setCurrentMonitor(monitorName);
  }, [monitorName]);

  useEffect(() => {
    if (collapsed || isLoading) return;

    if (window.innerWidth > screenSize.desktop) return;

    collapseSidebar();
  }, [isLoading]);

  if (isLoading || isFetching) return <Loading title={t('Monitor.Loading')} />;

  if (error || !data) return <ErrorMessage internalMessage={`Error getting dashboard id ${error}`} />;

  return <Monitor dashboardUrl={String(data)} sheet={sheet} />;
};

const mapStateToProps = (state: RootState) => {
  const { dashboards } = state?.client?.configuration || {};
  return { dashboards };
};

export default connect(mapStateToProps)(MonitorContainer);
