import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import {
  Row as ReactTableRow,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import { ToolState } from '@Models';
import { bopsApi } from '@Network';
import { RootState } from '@Store';
import ColumnPicker from '../ColumnPicker/columnPicker';
import IndeterminateCheckbox from '../IndeterminateCheckbox/indeterminateCheckbox';

export interface Column {
  Header?: string;
  accessor: string;
  Cell?: ({ row }) => void;
  width?: string;
}

export interface Row {
  values: object;
  depth: number;
  canExpand?: boolean;
  isExpanded?: boolean;
}

export interface TableFilter {
  column: string;
  value?: any;
}

interface TableProps {
  columns: Column[];
  dataResult: object[];
  pagination?: boolean;
  sortable?: boolean;
  footer?: boolean;
  columnPicker?: boolean;
  selectable?: boolean;
  filters?: string;
  startPagination: number;
  pageSize: number;
  total: number;
  endpoint: string;
  incrementName: string;
  revision: object;
  indexPagination: string;
  setTotal: (total: number) => void;

  getFilteredRows?(rows: ReactTableRow<object>[]);
}

const TableServer = (props: TableProps) => {
  const {
    columns,
    pagination,
    sortable,
    footer,
    columnPicker,
    dataResult,
    total,
    selectable,
    filters,
    getFilteredRows,
    endpoint,
    startPagination,
    indexPagination,
    pageSize,
    incrementName,
    revision,
    setTotal,
  } = props;

  const [data, setData] = useState(dataResult);
  const [totalPage, setTotalPage] = useState(total);
  const [currentPage, setCurrentPage] = useState(startPagination);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const incrementEvent = revision[incrementName] ?? 0;

  useEffect(() => {
    if (filters && currentPage !== 0) setCurrentPage(0);
  }, [filters]);

  const emptyString = (queryString: string): boolean => {
    const pairs = queryString.split('&');
    return pairs.some((pair) => {
      const [, value] = pair.split('=');
      return value !== undefined && value !== '';
    });
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await bopsApi[endpoint](filters, currentPage, pageSize);
        setData(response[indexPagination]);
        setLoading(false);
        setTotalPage(response.total);
        if (filters && emptyString(filters)) {
          setTotal(response.total);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, filters, incrementEvent]);

  const pageCount = totalPage ? Math.ceil(totalPage / pageSize) : 1;

  const {
    footerGroups,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    allColumns,
    getToggleHideAllColumnsProps,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selectable) {
        hooks.visibleColumns.push((visibleColumn) => [
          {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            ),
            Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
          },
          ...visibleColumn,
        ]);
      }
    },
  );

  if (getFilteredRows) {
    getFilteredRows(rows);
  }

  return (
    <div className="w-full overflow-x-auto">
      {columnPicker && (
        <div className="flex justify-end">
          <ColumnPicker allColumns={allColumns} getToggleHideAllColumnsProps={getToggleHideAllColumnsProps} />
        </div>
      )}
      <table className={`${loading ? 'animate-pulse ' : ''} border-collapse w-full`} {...getTableProps()}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              const sortByToggleProps = sortable ? column.getSortByToggleProps() : {};
              return (
                <th
                  className="text-[15px] leading-4 text-left bg-gray-50 border-l-0 border-r-0  border-solid border-gray-100 p-3 px-6 text-gray-400 font-medium"
                  {...column.getHeaderProps(sortByToggleProps)}>
                  {column.render('Header')}
                  {column.isSorted && <span>{column.isSortedDesc ? '  ↓' : '  ↑'}</span>}
                </th>
              );
            })}
          </tr>
        ))}
        <tbody {...getTableBodyProps()}>
          {pagination
            ? page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        className={`text-[15px] text-left border-l-0 border-r-0 border-t-0 border-b-1 border-solid border-gray-100 break-words leading-4 p-3 px-6 ${cell?.column?.width}`}
                        {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })
            : rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
        </tbody>

        {footer && (
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>

      {pagination && (
        <div className="flex place-items-center p-3 px-5">
          <span className="text-gray-300 text-sm">
            {t('Global.Page')} {currentPage + 1} {t('Global.Of')} {pageCount}
          </span>

          <div className="gap-2 flex ml-auto">
            <div
              className={`${
                currentPage === 0 ? 'pointer-events-none ' : ''
              }border border-solid border-gray-100 rounded-md p-2 cursor-pointer w-fit`}
              onClick={() => setCurrentPage(currentPage - 1)}>
              <span
                className={`${
                  currentPage === 0 ? 'text-gray-200 pointer-events-none' : 'text-gray-300'
                } text-sm font-semibold`}>
                {t('Global.Previous')}
              </span>
            </div>
            <div
              className={`${
                currentPage + 1 === pageCount || currentPage > pageCount ? 'pointer-events-none ' : ''
              }border border-solid border-gray-100 rounded-md p-2 cursor-pointer w-fit`}
              onClick={() => setCurrentPage(currentPage + 1)}>
              <span
                className={`${
                  currentPage + 1 !== pageCount ? 'text-gray-300' : 'text-gray-200'
                } text-sm font-semibold`}>
                {t('Global.Next')}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { revision } = state;
  return { revision };
};

export default connect(mapStateToProps)(TableServer);
export type { ReactTableRow };
