import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfigurationList, FileLinkCard } from '@Components';
import { Container, InnerContainer, Title, WelcomeMessage } from './configureLink.styles';

interface ConfigurationLink {
  title: string;
  description: string;
  link: string;
}

interface ConfigurationProps {
  configurationLinks: ConfigurationLink[];
}

const Configure = ({ configurationLinks }: ConfigurationProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <div className={`${loading ? 'pointer-events-none animate-pulse' : ' '} bg-white w-full  h-full`}>
      <div className="flex flex-col p-8 bg-white md:pr-[100px] md:pl-[100px] md:pt-[20px]">
        <div className="flex">
          <div className="text-3xl font-semibold mb-6  text-black-100">{t('Configuration.Title')}</div>
        </div>
        <div className="border border-gray-100 border-solid rounded-xl">
          <div className="flex md:flex-row mx-6 my-4 items-center flex-col">
            <span className="font-bold text-xl justify-start text-black-100">
              {`${t('Configuration.All')} (${configurationLinks?.length ?? 0})`}
            </span>
          </div>
          <ConfigurationList configures={configurationLinks ?? []} setLoading={setLoading} />
        </div>
      </div>
    </div>
  );
};

export default Configure;
