import { BarController } from 'chart.js';
import { MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import config from '../../config';

const sidebarBackgroundColor = 'linear-gradient(45deg, #101828, #344054)';

const navItemBackground = css`
  background-color: #101828;
`;

export const StyledNavMenuItem = styled.div<{ $collapsed: boolean; $isPathActive: boolean }>`
  ${({ $isPathActive }) => $isPathActive && navItemBackground}
`;

export const StyledMenuItem = styled(MenuItem)<{
  $isActive?: boolean;
  $isDisabled?: boolean;
}>`
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
  opacity: ${({ $isDisabled }) => ($isDisabled ? '0.4' : '1')};
  color: white;
  display: flex;

  .ps-menu-button {
    padding-left: 24px !important;
    padding-right: 12px !important;
  }

  .ps-menu-label {
    display: flex;
    align-items: center;
  }

  svg {
    color: white;
  }
`;

export const SidebarContainer = styled.div`
  background: ${sidebarBackgroundColor};
`;

export const StyledSidebar = styled(Sidebar).attrs(() => ({
  breakPoint: 'md',
  collapsedWidth: '80px',
  width: '285px',
  backgroundColor: `${sidebarBackgroundColor}!important`,
  rootStyles: {
    borderRight: '0px',
  },
}))`
  height: 100%;
  .ps-sidebar-container {
    background: ${sidebarBackgroundColor};
  }
  &.collapsed {
    background: ${sidebarBackgroundColor};
    &.sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }
`;

export const Header = styled.div<{ $collapsed: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: ${config.isGs1Flavor ? '110px' : '80px'};
  padding-top: 15px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: ${({ $collapsed }) => ($collapsed ? '20px' : '22px')};

  box-sizing: border-box;
  svg:first-of-type {
    align-self: center;
  }
`;

export const Separator = styled.div`
  // border-top: 2px solid #c0c0c0;
  margin: 5px 15px;
  padding-top: 5px;
`;

export const HeaderLogo = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 5px;
  svg {
    align-self: center;
    margin-left: auto;
    cursor: pointer;
  }
`;

export const Content = styled.div``;

export const Footer = styled.div`
  bottom: 40px;
  position: absolute;
  width: 100%;
  color: white;
  box-sizing: border-box;
`;

export const Anchor = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  :link {
    text-decoration: none;
  }
  :visited {
    text-decoration: none;
  }
  :hover {
    text-decoration: none;
  }
  :active {
    text-decoration: none;
  }
`;

export const SideBarText = styled.span<{ $collapsed: boolean }>`
  color: white;
  display: ${({ $collapsed }) => ($collapsed ? 'none' : 'inline')};
`;

const footerContainer = css`
  height: 50px;
  padding-left: ${config.isGs1Flavor ? '24px' : '25px'};
  align-items: center;
  display: flex;
  cursor: pointer;
  :hover {
    ${navItemBackground}
    background-color: #101828;
  }
  svg {
    padding-right: 17px;
    color: white;
  }
`;

export const LogoutContainer = styled.div`
  ${footerContainer}
`;

export const PageLinkContainer = styled.div`
  margin-right: 0px;
  ${footerContainer}
  padding-left: 20px;

  svg {
    padding-right: 14px;
  }
`;

export const SidebarLink = styled(NavLink)`
  &.active {
    // font-weight: bold;
  }
`;

export const StyledSubMenu = styled(SubMenu)<{ $collapsed: boolean; $isSubMenuOpen?: boolean }>`
  .ps-submenu-expand-icon {
    margin-top: -7px;
    ${({ $collapsed }) => $collapsed && 'display:none;'}
  }

  .ps-menu-button {
    padding-left: 24px !important;
    padding-right: 15px !important;
    .ps-menu-label {
      color: white;
      ${({ $collapsed }) => $collapsed && 'display:none;'}
    }
  }
  .ps-submenu-expand-icon {
    color: white;
  }

  .ps-submenu-content {
    background: ${({ $collapsed }) => ($collapsed ? sidebarBackgroundColor : '#00ff0000')};
    width: ${({ $collapsed }) => ($collapsed ? 'fit-content' : '100%')};
    .ps-menu-button {
      padding-left: 40px !important;
      padding-right: ${({ $collapsed }) => ($collapsed ? '40px!important' : '0px')};
      .ps-menu-label {
        display: block !important;
      }
    }
  }
`;

export const StyledExtraButton = styled.button<{ $collapsed: boolean; $isPathActive: boolean }>`
  margin-left: auto;
  border-radius: 50%;
  border: none;
  width: 30px;
  height: 30px;

  ${({ $isPathActive }) => !$isPathActive && 'display: none;'}

  background-color: #101828;

  :hover {
    background-color: #101828;
  }

  svg {
    color: white;
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  display: flex;
  margin: auto;
  color: white;
`;
