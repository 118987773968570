import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { showErrorToast } from '@Helpers';

import { ButtonNew, Input, ModalComponent, ModalLoading, ModalSuccess, Select } from '@Components';
import { Options, UserType } from '@Models';
import { bopsApi } from '@Network';
import { AppDispatch, tableServerRevision } from '@Store';
import config from '../../config';
import { SelectOptions } from '../Select/select';

interface UserModalProps {
  isOpen: boolean;
  onClose: () => void;
  clients: Options[];
  setloading: React.Dispatch<React.SetStateAction<boolean>>;
  setUsersDisplay: React.Dispatch<React.SetStateAction<UserType[]>>;
  fetchUsers: () => void;
  IncrementEvent: (event: string) => void;
  usersDisplay: UserType[];
  clientValue: Options | undefined;
}

interface FormFields {
  email: string;
  client: string;
  username?: string;
}

const UserAddModal = ({
  isOpen,
  onClose,
  clients,
  fetchUsers,
  setloading,
  clientValue,
  IncrementEvent,
}: UserModalProps) => {
  const { t } = useTranslation();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [emailCreated, setEmail] = useState('');

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormFields>();

  const emailValue = watch('email');

  useEffect(() => {
    reset();
  }, [isOpen]);

  const onSubmit = (data: FormFields) => {
    const { client, email, username } = data;
    setOpenLoading(true);
    setloading(true);
    setEmail(email);

    bopsApi
      .postUser({
        email,
        client,
        username,
      })
      .then(() => {
        onClose();
        setOpenLoading(false);
        setOpenSuccess(true);
        setTimeout(() => {
          IncrementEvent('usersEvent');
          fetchUsers();
        }, 3000);
        setloading(false);
      })
      .catch((error) => {
        setOpenLoading(false);
        setloading(false);
        let errorMessage = t('User.ErrorCreate');
        if (error.response?.data?.detail === 'User already exists') {
          errorMessage = t('User.ErrorAlreadyExists');
        }

        showErrorToast(t(errorMessage));
      });
  };

  return (
    <>
      <ModalComponent isOpen={isOpen} onClose={onClose} title={t('User.TitleModal')} closeClickOutside={false}>
        <div className="w-full">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pr-[20px]">
              <span className="text-black-200 text-sm" id="email">
                {`${t('User.Email')}*`}
              </span>
              <Input
                type="text"
                id="email"
                className={`${errors?.email ? 'border-red focus:border-red' : 'focus:border-gray-400'} mb-2`}
                {...register('email', {
                  required: `${t('User.Email')} ${t('Global.Required')}`,
                  validate: {
                    matchPattern: (v) => /^\S+@\S+\.\S+$/.test(v) || t('User.ErrorEmail'),
                  },
                })}
              />
            </div>
            {errors?.email && <p className="text-sm text-red">{errors?.email?.message}</p>}
            {config.isGs1Flavor && (
              <>
                <div className="pr-[20px]">
                  <span className="text-black-200 text-sm" id="username">
                    {`${t('User.Username')}*`}
                  </span>
                  <Input
                    type="text"
                    id="username"
                    className={`${errors?.email ? 'border-red focus:border-red' : 'focus:border-gray-400'} mb-2`}
                    {...register('username', {
                      required: `${t('User.Username')} ${t('Global.Required')}`,
                      validate: {
                        matchPattern: (v) =>
                          (v ? /^[a-zA-Z0-9_+\-.#!$'`~@^]+$/.test(v) : false) || t('User.ErrorUsername'),
                        notEmail: (v) => v !== emailValue || t('User.UsernameNotEmail'),
                      },
                    })}
                  />
                </div>
                {errors?.username && <p className="text-sm text-red">{errors?.username?.message}</p>}
              </>
            )}
            <div className="text-sm mt-4 mb-10">
              <Controller
                control={control}
                rules={{ required: true }}
                defaultValue={clientValue?.value}
                name="client"
                render={({ field: { onChange } }) => (
                  <Select
                    placeholder={t('User.Select')}
                    defaultValue={clientValue}
                    error={!!errors.client}
                    options={clients}
                    onChange={(val: SelectOptions) => {
                      onChange(val?.value || '');
                    }}
                    isClearable
                  />
                )}
              />
              {errors.client && <p className="text-sm text-red">{`${t('User.Client')} ${t('Global.Required')}`} </p>}
            </div>
            <div className="flex gap-2 justify-center mt-4 w-full">
              <ButtonNew
                onClick={() => {
                  onClose();
                }}
                className="w-full"
                isSubmit={false}>
                <div className="text-bg-gray-200"> {t('Global.Canceled')} </div>
              </ButtonNew>
              <ButtonNew isSubmit variant="primary" className="w-full">
                <div className="text-white"> {t('User.Add')} </div>
              </ButtonNew>
            </div>
          </form>
        </div>
      </ModalComponent>

      <ModalSuccess isOpen={openSuccess} onClose={() => setOpenSuccess(false)}>
        <div className="text-center">
          <span className="font-semibold text-[18px] text-center"> {emailCreated}</span>
          <span className="text-[18px]"> {t('User.AddSuccess')} </span>
        </div>
      </ModalSuccess>

      <ModalLoading isOpen={openLoading} onClose={() => setOpenLoading(false)}>
        <div className="text-center -mt-[9px]">
          <span className="font-semibold text-center"> {emailCreated}</span> {t('User.LoadingCreate')}
        </div>
      </ModalLoading>
    </>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  const { IncrementEvent } = tableServerRevision;

  return {
    IncrementEvent: (event: string) => dispatch(IncrementEvent(event)),
  };
};

export default connect(null, mapDispatchToProps)(UserAddModal);
