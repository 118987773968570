import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { UserType } from '@Models';

interface UserState {
  error?: string;
  isFetching: boolean;
  users?: UserType[];
  total?: number;
}

const initialState: UserState = {
  error: undefined,
  isFetching: false,
};

const reducers = {
  fetchUserFailure: (state: UserState, action: PayloadAction<{ error: string }>) => {
    const { error } = action.payload || {};
    state.error = error;
    state.isFetching = false;
  },

  fetchUserRequest: (state: UserState) => {
    state.error = undefined;
    state.isFetching = true;
  },

  fetchUserSuccess: (state: UserState, action: PayloadAction<{ users: UserType[]; total: number }>) => {
    const { users, total } = action.payload || {};
    state.users = users;
    state.total = total;
    state.isFetching = false;
  },
};

const slice = createSlice({ initialState, name: 'usersList', reducers });

export const { actions } = slice;
export default slice.reducer;
