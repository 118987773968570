import Modal from 'react-modal';

import { icon_close } from '@Assets';

const customStyles = {
  content: {
    top: '50%',
    left: '55%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    maxWidth: '500px',
    width: '100%',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
};
interface ModalProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  children: JSX.Element;
  hideClose?: boolean;
  closeClickOutside?: boolean;
}

const ModalComponent = ({
  isOpen,
  onClose,
  children,
  title,
  hideClose = false,
  closeClickOutside = true,
}: ModalProps) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={closeClickOutside ? onClose : null} style={customStyles} ariaHideApp={false}>
      <div>
        <div className="flex w-full mb-4">
          <span className="text-black-100 font-semibold text-xl">{title}</span>
          {!hideClose && (
            <div className="cursor-pointer ml-auto" onClick={onClose}>
              <img src={icon_close} alt="close_user" className="w-3 text-gray-100 ml-auto" />
            </div>
          )}
        </div>
        {children}
      </div>
    </Modal>
  );
};
export default ModalComponent;
