import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RevisionState {
  [key: string]: number;
}

// Initial state
const initialState: RevisionState = {};

// Create the slice
const revisionSlice = createSlice({
  name: 'revision',
  initialState,
  reducers: {
    refreshDataRevision: (state, action: PayloadAction<{ event: string }>) => {
      const eventKey = action.payload.event;
      state[eventKey] = (state[eventKey] ?? 0) + 1;
    },
  },
});

export const { refreshDataRevision } = revisionSlice.actions;
export default revisionSlice.reducer;
